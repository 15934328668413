<template>
   <div class="theone">
    <div>
      <img src="/images/logo-circle.svg" v-on:click="goTop()" />
      <div class="langChange">
        <a href="http://futurehistory.ru" class="langChangeElement pseudo-underline pseudo-underline2 show-line smallest">RU</a>
        <a href="http://futurehistory.studio" class="langChangeElement pseudo-underline pseudo-underline2 show-line smallest">EN</a>
      </div>
    </div>
    
    <div class="rotater">
       <a href="#contacts" :class="isActive('contacts')" class="pseudo-underline show-line smallest" v-html="$t('components.header.contacts')"></a>
       <a href="#team" :class="isActive('team')" class="pseudo-underline show-line smallest" v-html="$t('components.header.team')"></a>
       <a href="#projects" :class="isActive('projects')" class="pseudo-underline show-line smallest" v-html="$t('components.header.projects')"></a>
       <a href="#partners" :class="isActive('partners')" class="pseudo-underline show-line smallest" v-html="$t('components.header.partners')"></a>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {

   name: 'NavBar',

   data () {
      return {
        sectionId: 'site-header',
      }
   },
   mounted() {
   },

   computed: {
      ...mapGetters({
        activeSectionId: 'getActiveSectionId',
        locale: 'getLocale'
      })
   },
 
   methods: {
      isActive(sectionId) {
        return this.activeSectionId === sectionId ? 'hovered' : '';
      },
      goTop() {
         window.scrollTo({
            top: 0,
            behavior: "smooth"
         });
      }
   }
}
</script>

<style lang="scss" scoped>

  .pseudo-underline2:after {
    background-color: #ff9aab;
  }

   .theone {
      box-sizing: border-box;
      height: 100%;
      background-color: #423acc;
      white-space: nowrap;
      flex-direction: column;
      display: flex;
      justify-content: space-between;

      .langChange {
        margin: 20px auto;
        display: flex;
        transform: rotate(270deg);
        margin-top: 75px;

        .langChangeElement {
          // writing-mode: vertical-lr;        
          color: #ff9aab;
          // margin: 10px auto;
          display: block;
          cursor: pointer;
          margin: 0 10px;
          font-size: 16px;
          line-height: 2.38;
          // height: 30px;
        }
      }

      img {
         width: 29px;
         height: 29px;
         margin: 10px auto;
         display: block;

         cursor: pointer;
         z-index: 1;
      }

      .rotater {
         transform: rotate(270deg);

         a {
           font-size: 16px;
           line-height: 2.38;
           color: #ffffff;
           margin: 0 10px;
         }
      }

      
   }
</style>