<template>
  <div class="flexer">
    <div class="asider" :id="sectionId" v-html="$t('components.contacts.title')"></div>
    <section class="contacts" v-scroll="onScroll">
      <p v-html="$t('components.contacts.p1')" class="description" :class="`locale-${locale}`"></p>
      <p class="description" v-html="$t('components.contacts.p2')" :class="`locale-${locale}`"></p>
      <h2 v-html="$t('components.contacts.socials.title')"></h2>
      <div class="socials-wrapper" >
        <a :href="link.url" v-for="(link) in links" target="_blank" class="pseudo-underline show-line black-line" :class="`locale-${locale}`">{{link.name}}</a>
      </div>
      <img src="/images/logo.gif">
      <p class="description" :class="`locale-${locale}`" v-html="$t('components.contacts.p3')"></p>
    </section>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: "contacts",
    data() {
      let localsBase = 'components.contacts.socials';
      let links = this.getLocalized(localsBase , 'links') || [];

      return {
        sectionId: 'contacts',
        links: links.map((v) => {
          return {
            name: v.name,
            url: v.url
          }
        })
      }
    },
    computed: {
      ...mapGetters({
        locale: 'getLocale'
      }),
    }
  }
</script>

<style lang="scss">
  .contacts {
    // margin-top: 102px;
    padding-bottom: 96px;
    width: 1080px;

    .description {
      font-size: $desktop-h1-fontsize;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: #ffffff;

      a {
        color: #ffffff;
      }

      @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
        font-size: $tablet-h1-fontsize;
        line-height: 1.2;
      }

      @media all and (max-width: $screen-phone-max-width) {
        font-size: 9.06vw;
        line-height: 8.53vw;
        width: 78.76vw;
      }
    }

    .description:nth-of-type(2) {
      margin-top: 83px;
      margin-bottom: 83px;

      @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
        margin-top: 30px;
        margin-bottom: 58px;
      }

      @media all and (max-width: $screen-phone-max-width) {
        margin-top: 30px;
        margin-bottom: 42px;
      }
    }

    .description:nth-of-type(3) {
      margin-bottom: 83px;
    }

    h2 {
      font-size: $desktop-p-fontsize;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #ffffff;
      margin-bottom: 10px;

      @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
        font-size: $tablet-p-fontsize;
        line-height: 1;
      }

      @media all and (max-width: $screen-phone-max-width) {
        font-size: 4.8vw;//18px;
        line-height: 4.8vw;
        margin-bottom: 42px;
      }
    }

    .socials-wrapper {

      

      a {
        font-size: $desktop-h1-fontsize;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #000000;
        text-decoration: none;
        margin-right: 50px;

        &.locale-en {
          @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
            font-size: 22px;
            margin-right: 30px;
          }
          
        }

        @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
          font-size: $tablet-h1-fontsize;
          line-height: 20px;
          margin-right: 40px;
        }

        @media all and (max-width: $screen-phone-max-width) {
          font-size: 9.06vw;
          line-height: 8.53vw;
          display: block;
          margin-bottom: 20px;
        }
      }
    }

    img {
      width: 300px;
      margin-top: 164px;
      margin-bottom: 29px;
      margin-left: -70px;

      @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
        width: 200px;
        margin-top: 61px;
        margin-bottom: 27px;
        margin-left: -50px;
      }

      @media all and (max-width: $screen-phone-max-width) {
        width: 82vw;
        margin-top: 31.46vw;//118px;
        margin-bottom: 15.2vw;//57px;
        margin-left: -13.33vw;
      }
    }

    @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
      // margin-top: 63px;
      // padding-bottom: 85px;
      // width: 524px;
    }

    @media all and (max-width: $screen-phone-max-width) {
      width: 78.66vw;
      padding-bottom: 0;
    }
  }
</style>
