<template>
    <div class="team-member" :class="`team-member-${index}`">
      <img :src="photoUrl">
      <div class="info-container">
        <p class="name" v-html="name" :class="`locale-${locale}`"></p>
        <p class="position" v-html="position"></p>
      </div>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: "team-member",
    props: ['name', 'position', 'photoUrl', 'index'],
    computed: {
      ...mapGetters({
        locale: 'getLocale'
      }),
    }
  }
</script>

<style lang="scss">
  .team-member {
    width: 50%;
    // float: left;

    &.team-member-1 {
      .info-container {
        .position {
          @media all and (max-width: $screen-phone-max-width) {
            width: 53.33vw;
          }
        }
      }
    }

    img {
      width: 246px;
      border-radius: 50%;

      @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
        width: 138px;
      }

      @media all and (max-width: $screen-phone-max-width) {
        width: 65.6vw;
        margin-left: -14px;
      }
    }
    
    .info-container {
      display: inline-block;
      width: 319px;
      margin-left: -56px;

      .name {
        font-size: $desktop-h1-fontsize;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: #ffffff;

        // &.locale-ru {
        //   @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
        //     font-size: 25px;
        //     line-height: 25px;
        //   }
        // }

        @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
          font-size: $tablet-h1-fontsize;
          line-height: 1;
        }

        @media all and (max-width: $screen-phone-max-width) {
          font-size: 9.06vw;
          line-height: 8.53vw;
          width: 53.33vw;
        }
      }

      .position {
        font-size: $desktop-p-fontsize;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 30px;
        letter-spacing: normal;
        color: #ffffff;

        @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
          font-size: $tablet-p-fontsize;
          line-height: 17px;
        }

        @media all and (max-width: $screen-phone-max-width) {
          margin-top: 10px;
          font-size: 4.8vw;
          line-height: 4.8vw;
        }
      }

      @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
        width: 150px;
        margin-left: -37px;
      }

      @media all and (max-width: $screen-phone-max-width) {
        display: block;
        margin: 0;
        margin-top: -11vw;
        width: 70.6vw;
      }
    }

    @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
      width: 300px;
    }

    @media all and (max-width: $screen-phone-max-width) {
      float: none;
      margin-bottom: 33px;
    }
  }
</style>
