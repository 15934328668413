<template>
  <div class="flexer">
    <div class="asider" :id="sectionId"></div>
    <section class="partners" v-scroll="onScroll">
      <h1 v-html="$t('components.partners.title')"></h1>
      <img :src="$t('components.partners.srcDesktop')" class="desktop" style="width: 100%;" />
      <img :src="$t('components.partners.srcMob')" class="mobile" />
    </section>
  </div>
</template>

<script>
  export default {
    name: 'features',
    data () {
      return {
        sectionId: 'partners'
      }
    }
  }
</script>

<style lang="scss">
  .partners {
    // margin-top: 88px;
    // width: 1080px;

    h1 {
      font-size: $desktop-h1-fontsize;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: #ffffff;
      margin-bottom: 54px;

      @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
        font-size: $tablet-h1-fontsize;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        margin-bottom: 30px;
      }

      @media all and (max-width: $screen-phone-max-width) {
        font-size: 9.06vw;//34px;
        line-height: 0.94;
        margin-bottom: 42px;
        width: 80.53vw;
      }
    }

    img {
      &.desktop {
        // margin-bottom: 130px;

        @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
          width: 544px;
          margin-bottom: 64px;
        }

        @media all and (max-width: $screen-phone-max-width) {
          display: none;
        }
      }

      &.mobile {
        display: none;
        @media all and (max-width: $screen-phone-max-width) {
          display: block;
          width: 74.13vw;
        }
      }
    }

    @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
      // width: 524px;
      // margin-top: 50px;
    }
  }
</style>
