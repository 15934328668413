<template>
  <div class="flexer">
    <div class="asider" :id="sectionId" v-html="$t('components.team.title')"></div>
    <section class="team" v-scroll="onScroll">
      <div class="wrapper-heads">
        <team-member v-if="founder"
           :name="locale == 'ru' ? founder.name : founder.name_EN"
           :position="locale == 'ru' ? founder.role : founder.role_EN"
           :photoUrl="apiUrl + founder.photo.url">
        </team-member>
      </div>
      <div class="full-team-header" v-on:click="onShowClick" :class="`locale-${locale}`">
        <span v-if="!isOthersShown">+</span>
        <span v-if="isOthersShown">&minus;</span>
        <span class="title pseudo-underline hide-line" :class="`locale-${locale}`" v-html="$t('components.team.team')"></span>
      </div>
      <div class="full-members-list" v-if="isOthersShown">
        <div class="team-photo">
          <img src="/images/team.gif" style="width: 100%; height: 100%;">
          <div class="gradientOnDesktop"></div>
        </div>
        <div class="full-members-list-member" v-for="(member) in otherMembers">
          <div class="name" v-html="locale == 'ru' ? member.name : member.name_EN"></div>
          <div class="position" v-html="locale == 'ru' ? member.role : member.role_EN"></div>
        </div>
      </div>
      <div class="spacer" style="clear: both;"></div>
    </section>
  </div>
</template>

<script>
  import TeamMember from '~/components/team-member.vue';
  import { mapGetters } from 'vuex';

  export default {
    name: "team",
    components: {
      TeamMember
    },
    computed: {
      ...mapGetters({
        locale: 'getLocale',
        apiUrl: 'getApiUrl'
      })
    },
    data() {
      let localsBase = 'components.team';
      let members = this.getLocalized(localsBase , 'members') || [];
      // let otherMembers = this.getLocalized(localsBase , 'otherMembers') || [];    

      return {
        sectionId: 'team',
        founder: null,
        otherMembers: null,
        isOthersShown: false
      }
    },
    mounted() {
      fetch(this.apiUrl + "/teammates/", {redirect: 'follow'})
        .then(rawdata => { return rawdata.json() })
        .then(json => { 
          console.log(json) 
          this.otherMembers = json
        })

      fetch(this.apiUrl + "/founder/", {redirect: 'follow'})
        .then(rawdata => { return rawdata.json() })
        .then(json => { 
          console.log(json) 
          this.founder = json
        })
    },
    methods: {
      onShowClick() {
        this.isOthersShown = !this.isOthersShown;
        window.setTimeout(() =>  {
          this.onResize();
          this.checkIsInViewport();
        }, 10);
      }
    }
  }
</script>

<style lang="scss">
  .team {
    width: 1080px;
    // margin-top: 100px;

    .wrapper-heads {
      display: flex;

      @media all and (max-width: $screen-phone-max-width) {
        flex-direction: column;
      }
    }

    .full-team-header {
      font-size: $desktop-h1-fontsize;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: #ffffff;
      cursor: pointer;

      // &.locale-ru {
      //   @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
      //     font-size: 25px;
      //   }
      // }

      span {
        display: block;
      }

      .title {
        width: 129px;
        display: inline;

        &.locale-ru {
          // width: 218px;
          width: 100%;

          @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
            width: 108px;
          }
        }

        @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
          width: 71px;
          line-height: 25px;
        }
      }

      @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
        font-size: $tablet-h1-fontsize;
      }

      @media all and (max-width: $screen-phone-max-width) {
        font-size: 9.06vw;//34px;
        line-height: 0.94;
      }
    }

    .full-members-list {
      padding-top: 70px;
      position: relative;

      .team-photo {
        position: absolute;
        width: 748px;
        height: 541px;
        top: 230px;
        left: 275px;

        @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
          width: 413px;
          height: 299px;
          top: 135px;
          left: 190px;
        }

        @media all and (max-width: $screen-phone-max-width) {
          position: unset;
          width: 88.26vw;
          height: unset;
          margin-left: -14px;
        }
      }

      .full-members-list-member {
        position: relative;
        z-index: 1;
        color: #ffffff;
        font-style: normal;
        font-stretch: normal;
        line-height: 28px;
        letter-spacing: normal;

        .name {
          font-size: $desktop-h1-fontsize;
          font-weight: 700;
          margin-top: 30px;

          @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
            font-size: $tablet-h1-fontsize;
            font-weight: bold;
            line-height: 18px;
            margin-top: 20px;
          }

          @media all and (max-width: $screen-phone-max-width) {
            font-size: 7.46vw;
            line-height: 0.75;
          }
        }

        .position {
          font-size: $desktop-p-fontsize;
          font-weight: 600;
          margin-top: 10px;

          a {
            color: inherit;

            &:hover {
              text-decoration: none;
            }
          }

          @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
            font-size: $tablet-p-fontsize;
            font-weight: 600;
            line-height: 18px;
            margin-top: 5px;
          }

          @media all and (max-width: $screen-phone-max-width) {
            font-size: 4.8vw;
            line-height: 0.75;
          }
        }
      }

      .full-members-list-member:nth-of-type(1) {
        @media all and (min-width: $screen-tablet-min-width) {
          .name {
            margin-top: 0;
          }
        }
      }

      @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
        padding-top: 25px;
      }

      @media all and (max-width: $screen-phone-max-width) {
        padding-top: 20px;
      }
    }

    @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
      // width: 524px;
      // margin-top: 65px;
    }

    @media all and (max-width: $screen-phone-max-width) {
      width: 83.73vw;
    }
    
    @media all and (min-width: $screen-tablet-min-width) {
      .gradientOnDesktop {
        background: linear-gradient(to right, rgba(0,0,0,0.3), transparent 25%);
        mix-blend-mode: multiply;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    


  }
</style>
