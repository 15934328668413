<template lang="html">
  <header v-resize="onResize" class="site-header">
    <img src="/images/logo.gif">    
    <div class="headers" :class="`locale-${locale}`">
      <h1>
        <span class="title" v-html="$t('components.header.tabs[0].title')"></span>
        <span class="subtitle left-subtitle">
          <span v-html="$t('components.header.tabs[0].subtitle')">Production studio</span>
        </span>
      </h1>
      <h1>
        <span class="subtitle" v-html="$t('components.header.tabs[1].subtitle')"></span>
        <span class="title" v-html="getTitle('components.header.tabs[1]')"></span>
      </h1>
      <h1>
        <span class="subtitle" v-html="$t('components.header.tabs[2].subtitle')"></span>
        <span class="title" v-html="getTitle('components.header.tabs[2]')"></span>
      </h1>
      <h1>
        <span class="subtitle" v-html="$t('components.header.tabs[3].subtitle')"></span>
        <span class="title" v-html="getTitle('components.header.tabs[3]')"></span>
      </h1>
    </div>
  </header>
</template>
<script lang="js">
  import { mapGetters } from 'vuex';

  export default {
    data () {
      return {
        sectionId: 'site-header',
      }
    },
    mounted() {
      this.onResize();
    },
    computed: {
      ...mapGetters({
        activeSectionId: 'getActiveSectionId',
        locale: 'getLocale'
      })
    },
    methods: {
      isActive(sectionId) {
        return this.activeSectionId === sectionId ? 'hovered' : '';
      }
    }
  }
</script>
<style lang="scss">
  header {
    display: flex;
    position: relative;
    background-image: linear-gradient(to right, #423acc, #9295ee);
    width: 100vw;
    height: 760px;

    .headers {
      display: flex;

      &.locale-ru {
        h1 {
          font-size: 40px;
          line-height: 40px;
          width: 26.06vw;

          @media all and (min-width: $screen-desktop-min-width-ru) {
            font-size: 50px;
            line-height: 50px;
            width: 26.18vw;

            .title, .subtitle {
              width: 404px;//25.25vw;
            }
          }

          @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
            font-size: 25px;
            line-height: 25px;
          }

          @media all and (max-width: $screen-phone-max-width) {
            font-size: 9.06vw;
            line-height: 8.53vw;
            width: unset;

            .title {
              width: 80.53vw;
            }
          }
        }

        h1:nth-of-type(1) {
          width: 19.03vw;

          @media all and (min-width: $screen-desktop-min-width-ru) {
            width: 19.06vw;
          }

          @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
            font-size: 25px;
            line-height: 25px;
            width: 19.79vw;
          }

          @media all and (max-width: $screen-phone-max-width) {
            width: unset;
            font-size: 9.06vw;
            line-height: 8.53vw;
          }
        }
      }

      h1 {
        width: 26.29vw;
        font-size: 50px;
        font-weight: 800;
        font-style: normal;
        font-stretch: normal;
        line-height: 50px;
        letter-spacing: normal;
        color: #ffffff;
        padding-top: 299px;

        .title {
          display: block;
          width: 318px;
          margin: 0 auto;

          @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
            width: 175px;
          }

          @media all and (max-width: $screen-phone-max-width) {
            width: unset;
            margin: 0;
          }
        }

        .subtitle {
          display: block;
          font-size: 24px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 30px;
          letter-spacing: normal;
          width: 318px;
          margin: 0 auto;

          @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
            font-size: 14px;
            line-height: 17px;
            width: 175px;
          }

          @media all and (max-width: $screen-phone-max-width) {
            font-size: 4.8vw;
            line-height: 4.8vw;
            margin: unset;
            width: 84.8vw;
          }
        }

        @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
          width: 25.13vw;
          padding-top: 151px;
          font-size: 28px;
          line-height: 28px;
        }

        @media all and (max-width: $screen-phone-max-width) {
          width: 88.26vw;
          font-size: 9.06vw;
          line-height: 8.53vw;
          padding-left: 3.73vw;
          padding-right: 5.86vw;
        }
      }

      h1:nth-of-type(1) {
        background-color: #4a4ce2;
        width: 19.5vw;

        color: #ff9aab;

        .title, .subtitle {
          width: 225px;
          margin: 0 0 0 18px;

          @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
            width: 113px;
            margin: 0 0 0 18px;
          }

          @media all and (max-width: $screen-phone-max-width) {
            width: unset;
            margin: unset;
          }
        }

        .title {
          text-decoration: underline;
        }

        .subtitle {
          margin-top: 10px;
          span {
            display: block;
            width: 200px;

            @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
              width: 100px;
            }

            @media all and (max-width: $screen-phone-max-width) {
              width: unset;
            }
          }

          @media all and (max-width: $screen-phone-max-width) {
            margin-top: 10px;
          }
        }

        @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
          width: 19.79vw;
          padding-top: 147px;
          font-size: 28px;
          line-height: 28px;
        }

        @media all and (max-width: $screen-phone-max-width) {
          padding-top: 6.66vw;
          padding-bottom: 5.6vw;
          width: 88.26vw;
          padding-right: 5vw;
          padding-left: 3.73vw;
        }
      }

      h1:nth-of-type(2) {
        background-color: #6e74e8;

        @media all and (max-width: $screen-phone-max-width) {
          padding-top: 6.93vw;
          padding-bottom: 9.33vw;
        }
      }

      h1:nth-of-type(3) {
        background-color: #8085eb;

        @media all and (max-width: $screen-phone-max-width) {
          padding-top: 9.06vw;
          padding-bottom: 9.33vw;
        }
      }

      h1:nth-of-type(4) {
        background-color: #9295ee;

        @media all and (max-width: $screen-phone-max-width) {
          padding-top: 5.6vw;
          padding-bottom: 5.06vw;
        }
      }

      @media all and (max-width: $screen-phone-max-width) {
        flex-direction: column;
      }
    }

    img {
      position: absolute;
      width: 25vw;
      max-width: 342px;
      top: 39px;
      z-index: 1;

      @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
        width: 29vw;
        top: 14px;
        left: 0;
        max-width: 223px;
      }

      @media all and (max-width: $screen-phone-max-width) {
        display: none;
      }
    }

    @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
      height: 428px;
    }

    @media all and (max-width: $screen-phone-max-width) {
      height: auto;
      background-color: #423acc;
      background-image: none;
    }
  }
</style>
