<template>
  <div class="flexer">
    <div class="asider" v-html="$t('components.projects.title')"></div>
    <section class="projects" v-scroll="onScroll">
      <div class="wrapper-prj">
        <project class="theproject" v-for="(project, index) in projects"
           :key="index"
           :index="`1-${index}`"
           :mobileName="locale == 'ru' ? project.mobileName : project.mobileName_EN"
           :nameSeparated="locale == 'ru' ? project.name.split(' ') : project.name_EN.split(' ')"
           :description="locale == 'ru' ? project.description : project.description_EN"
           :link="project.link"
           :cover="apiUrl + project.cover.url"
           :hoverVideo="apiUrl + project.hoverVideo.url"
           :achievementMobile="project.achievementMobile"
           :achievement="locale == 'ru' ? project.achievement : project.achievement_EN"
           :customLetterSpacing="project.customLetterSpacing">
        </project>
      </div>
      <div class="pojects-footer" :class="`locale-${locale}`">
        <span>+</span>
        <span v-html="$t('components.projects.footer[0]')"></span>  
        <span v-html="$t('components.projects.footer[1]')"></span>
      </div>
    </section>
  </div>
</template>

<script>
    import Project from '~/components/project.vue';
    import jss from 'jss';
    import { mapGetters } from 'vuex';
    const phoneMediaQuery = '@media screen and (max-width: 767px)';
    const tabletMediaQuery = '@media screen and (min-width: 768px) and (max-width: 1365px)';
    const desktopMediaQuery = `@media screen and (min-width: 1366px)`;
    const project = 'project';
    const absolute = 'absolute';

    export default {
      name: "projects",
      components: {
        Project
      },
      data() {
        return {
          projects: null
        }
      },
      mounted() {
        fetch(this.apiUrl + "/projects/?_sort=sort:ASC", {redirect: 'follow'})
        .then(rawdata => { return rawdata.json() })
        .then(json => { 
          console.log(json) 
          this.projects = json
        })
      },
      computed: {
        ...mapGetters({
          locale: 'getLocale',
          apiUrl: 'getApiUrl'
        })
      }
    }
</script>

<style lang="scss" >
  
  .theproject {
    position: relative;
  }
  
  .project-1-0 a .desktop-wrapper .image-wrapper{
    bottom: 30px;
  }

  .project-1-1 a .desktop-wrapper .image-wrapper{
    bottom: 60px;
  }

  .project-1-2 a .desktop-wrapper .image-wrapper{
    bottom: 0px;
  }

  .project-1-3 a .desktop-wrapper .image-wrapper{
    bottom: 30px;
  }

  .project-1-4 a .desktop-wrapper .image-wrapper{
    bottom: 0px;
  }

  .project-1-5 a .desktop-wrapper .image-wrapper{
    bottom: 60px;
  }

  .wrapper-prj {
    display: flex;
    flex-wrap: wrap;
  }

  .projects {
      // @media all and (max-width: $screen-phone-max-width) {
      //   margin-top: unset;
      // }

    .pojects-footer {
      font-size: $desktop-h1-fontsize;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.1;
      letter-spacing: normal;
      color: white;
      // width: 678px;
      // margin-left: 326px;
      margin-bottom: 30px;
      margin-top: -30px;

      span {
        display: block;
      }

      // &.locale-ru {
      //   @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
      //     font-size: 25px;
      //     line-height: 25px;
      //     margin-top: 0px;
      //   }
      // }

      @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
        font-size: $tablet-h1-fontsize;
        line-height: 1.1;
        margin-top: 0;
        // margin-left: 200px;
      }

      @media all and (max-width: $screen-phone-max-width) {
        // width: 64.8vw;
        // margin: 44px 0 76px 0;
        margin-top: 30px;
        font-size: 9.06vw;
        line-height: 0.94;
      }
    }

    @media all and (min-width: $screen-tablet-min-width) and (max-width: $screen-tablet-max-width) {
      margin-top: 55px;
      // width: 524px;
    }

    @media all and (max-width: $screen-phone-max-width) {
      width: 83.73vw;
      margin-top: unset;
      margin-right: 0;

      .project:not(:nth-of-type(1)) {
        //padding-top: 40px;
      }
    }
  }
</style>
