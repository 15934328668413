<template lang="html">
  <div class="content">
    <nav-bar class="left"/>
    <div class="right">
      <header-content/>
      <features class="oneComponent"/>
      <projects class="oneComponent"/>
      <team class="oneComponent"/>
      <contacts class="oneComponent"/>
    </div>
  </div>
</template>
<script lang="js">
  import HeaderContent from '~/components/header.vue';
  import NavBar from '~/components/navbar.vue';
  // import $ from 'jquery';

  import Features from '~/components/features.vue';
  import Projects from '~/components/projects.vue';
  import Team from '~/components/team.vue';
  import Contacts from '~/components/contacts.vue';
  import { mapGetters } from 'vuex';
  import _ from 'lodash';

  export default {
    components: {
      HeaderContent,
      NavBar,
      Features,
      Projects,
      Team,
      Contacts
    },
    mounted () {
      this.$nextTick(() => {
        this.$nuxt.$loading.start();
        setTimeout(() => {
          document.querySelector('.content').style.opacity = 1
          this.$nuxt.$loading.finish()
        }, 10);
      })
    },
    data() {
      return {
      }
    },
    head() {
      return {
        title: this.$t('meta.title'),
        meta: [
          { hid: 'description', name: 'description', content: this.$t('meta.description') }
        ],
      }
    },
    computed: {
      ...mapGetters({
      })
    },
    methods: {
      goTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
    }
  }
</script>
<style lang="scss" scoped="">
  .content {
    opacity: 0;
    transition: opacity 1s ease;
    position: relative;
    width: 100%;
    display: flex;
  }

  .left {
    width: 38px;
    position: fixed;
    z-index: 99;
  }

  .right {
    margin-left: 38px;
  }
</style>
